
import { defineComponent, computed, reactive } from 'vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {
    collapse: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const userMenus = computed(() => store.state.login.userMenus)
    const handleMenuItemClick = (subitem: any) => {
      // console.log(subitem)
      //如果没有就跳到not-found页面
      router.push({
        path: subitem.url ?? '/not-found',
      })
    }
    return { userMenus, handleMenuItemClick }
  },
})
