
import { defineComponent, ref } from 'vue'
import UserInfo from './user-info.vue'

export default defineComponent({
  emits: ['foldChange'],
  components: { UserInfo },
  setup(props, { emit }) {
    const isFold = ref(false)
    // 改变
    const handleFoldClick = () => {
      isFold.value = !isFold.value
      emit('foldChange', isFold.value)
    }
    return { isFold, handleFoldClick }
  },
})
