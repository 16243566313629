
import { defineComponent, inject, ref } from 'vue'
import NavMenu from '@/components/nav-menu'
import NavHeader from '@/components/nav-header'
import router from '@/router'
import { mapMenusToRouters } from '@/utils/map-menus'
import { useStore } from '@/store'
import { UpdateGoodsStatus } from '@/service/main/main'

export default defineComponent({
  components: { NavMenu, NavHeader },
  setup() {
    const isCollapse = ref(false)
    // 接受子组件的值 isFold
    const handleFoldChange = (isFold: boolean) => {
      isCollapse.value = isFold
    }
    // 映射组件 动态生成路由
    const store = useStore()
    const routes = mapMenusToRouters(store.state.login.userMenus)
    routes.forEach((item) => {
      router.addRoute('main', item)
    })
    // 刷新页面
    const reload: any = inject('reload')
    const updataShop = () => {
      UpdateGoodsStatus()
      reload()
    }
    return {
      isCollapse,
      handleFoldChange,
      updataShop,
    }
  },
})
